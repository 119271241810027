import React from 'react';
import DefaultProfile from '../default_profile_pic.png' 

const ProfileDetails = ({ name, picture, favoriteSong }) => {
  return (
    <div className="profile-details">
      <img className="profile-pic" src={"../default_profile_pic.png"} alt={name} />
      <h1>{name}</h1>
    </div>
  );
};

export default ProfileDetails;
