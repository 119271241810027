import React from 'react';
import './Navbar.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom';

import LogoImage from '../boxlogo_transparent.png' 

import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button, Heading, View } from '@aws-amplify/ui-react';

const Navbar = () => {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }

  return (
    
    <div className="navbar">
      <div className="nav-options">
      <Link to="/"><img src={LogoImage} alt="Logo" className="logo" /></Link>
      
      </div>
      <div className="nav-options">
      <div className='nav-text nav-page-title'>UWaterloo Hip Hop Club</div>
      </div>
      <div className="nav-options">
      <Link to="/past-sessions" className="nav-link nav-option">Past Sessions</Link>
      <div className='nav-divider'></div>
      <Link to="/calendar" className="nav-link nav-option">Calendar</Link>
      <div className='nav-divider'></div>
      <Link to="/people" className="nav-link nav-option">People</Link>
      </div>
      <div className="nav-options admin-options">
      <Link to="/admin" className="nav-link nav-option">Admin</Link>
      {route !== 'authenticated' ? (
          <Button variation="primary" onClick={() => {
            navigate('/login');
          }}>Login</Button>
        ) : (
          <Button variation="primary" onClick={() => logOut()}>Logout</Button>
        )}
      </div>
      
        <Outlet />
        
      </div>
      
      
    
  );
};

export default Navbar;