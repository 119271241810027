// RequireAuth.js
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

export function RequireAuth({ children }) {
  const location = useLocation();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  if (authStatus === 'unauthenticated') {
    console.log(authStatus);
    
  
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}