// PageTemplate.js
import React from 'react';
import Navbar from './Navbar';

const PageTemplate = ({ children }) => {
  return (
    <div className="page-template">
      <div className='divider-top'></div>
      <div className="page-content">{children}</div>
    </div>
  );
};

export default PageTemplate;