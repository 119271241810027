// PastSessions.js
import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { Link } from "react-router-dom"
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
  Autocomplete
} from "@aws-amplify/ui-react";
import './SessionsForm.css'; // Import your CSS file for stylin

import { listDanceSessions, listLocations, listSessionAttendees } from "../../graphql/queries";

import {
  deleteDanceSession as deleteDanceSessionMutation,
} from "../../graphql/mutations";

import {
  createDanceSession as createDanceSessionMutation,
} from "../../graphql/mutations"

import { deleteSessionAttendees } from "../../graphql/mutations";


const SessionsForm = () => {
  const [sessions, setDanceSessions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState('');
  const myRef = React.createRef();
  useEffect(() => {
    fetchDanceSessions();
    
  }, []);

  useEffect(() => {
    async function fetchLocations() {
      const apiData = await API.graphql({ query: listLocations });
      const locationsFromAPI = apiData.data.listLocations.items;
      setLocations(locationsFromAPI);
      
    }
    fetchLocations();
    
  }, [locations]);
  

  

  async function fetchDanceSessions() {
    const apiData = await API.graphql({ query: listDanceSessions });
    const sessionsFromAPI = apiData.data.listDanceSessions.items;
    setDanceSessions(sessionsFromAPI);
    
  }

  async function createDanceSession(event) {
    
    event.preventDefault();
    const form = new FormData(event.target);

    const image = form.get("image");
    console.log(image.name);
    const data = {
      Date: form.get("date"),
      Time: form.get("time"),
      TeacherName: form.get("teacherName"),
      SongName: form.get("songName"),
      Thumbnail: image.name,
      VideoLink: form.get("videoLink")
    };
    var unix = Math.round(+new Date()/1000).toString();
    if (!!data.Thumbnail) await Storage.put(image.name, image);
    console.log(data);
    await API.graphql({
      query: createDanceSessionMutation,
      variables: { input: data },
    });
    fetchDanceSessions();
    event.target.reset();
  }

  async function editDanceSession(event) {

  }

  async function deleteSessionAttendeesBySession(id){
    console.log(id);
    const attendeesData = await API.graphql({
      query: listSessionAttendees,
      variables: {
        filter: {danceSessionId: {eq: id}}
      }
    })

    const sessionAttendees = attendeesData.data.listSessionAttendees.items;

      for(const sessionAttendee of sessionAttendees){
        console.log(sessionAttendee.id);
        const sessionAttendeeId = sessionAttendee.id;

        await API.graphql({
          query: deleteSessionAttendees,
          variables: {input: {id:sessionAttendeeId}}
        });
      }
    
      
  }
  async function deleteDanceSession({ id, name }) {
    const newDanceSessions = sessions.filter((session) => session.id !== id);
    setDanceSessions(newDanceSessions);

    await deleteSessionAttendeesBySession(id);

    await Storage.remove(name);
    await API.graphql({
      query: deleteDanceSessionMutation,
      variables: { input: { id } },
    });
  }
  if(!locations){
    return(<div className="admin-form">Loading...</div>)
  }
  return (
      <div className="admin-form">
        <h2>Create Dance Session</h2>
        <View className="admin-view" as="form" margin="3rem 0" onSubmit={createDanceSession}>
        <Flex direction="row" justifyContent="left" style={{"margin-bottom":"8px"}}>
          <TextField
            name="songName"
            placeholder="Song Name"
            label="Song Name"
            labelHidden
            variation="quiet"
            required
          />
          <TextField
            name="teacherName"
            placeholder="Teacher Name"
            label="Teacher Name"
            labelHidden
            variation="quiet"
            required
          />
          <TextField
            name="time"
            placeholder="00:00:00"
            label="Time"
            type="time"
            labelHidden
            variation="quiet"
            required
          />
          <TextField
            name="date"
            placeholder="2023-01-01"
            label="Date"
            type="date"
            labelHidden
            variation="quiet"
            required
          />
          </Flex>
          <Flex direction="row" justifyContent="left">
          
          <Autocomplete
            
            label="Location"
            labelHidden
            name="locationId"
            options={locations?locations.map(location => ({
              id: location.id,
              label: location.name // or whatever property holds the name
          })):[]}
            placeholder="Location..."
            onSelect={(option) => {
              setLocation(option.id);
            }}
          />
          <View
            name="image"
            as="input"
            type="file"
            style={{ alignSelf: "end" }}
          />
          <TextField
            name="videoLink"
            placeholder="youtube.com/videoLink"
            label="Video Link"
            labelHidden
            variation="quiet"
          />
          </Flex>
          <Flex direction="row" justifyContent="right">
          <Button type="submit" variation="primary">
            Create Dance Session
          </Button>
          
        </Flex>
      </View>
      

      <h2>Dance Sessions</h2>
      <View margin="1rem 0">
        <table className="sessions-table">
          <tbody>
          <tr>
            <th>Link</th>
            <th>Choreographer</th>
            <th>Date</th>
            <th>Time</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        {!sessions?<></>:sessions.sort((a, b) => new Date(b.Date) - new Date(a.Date)).map((session) => (
          
            <tr key={session.id || session.TeacherName}>
              
              <td>
                  <Link to={`/session/${session.id}`}>View Session</Link>
              </td>
              <td><Text as="strong" fontWeight={700}>
              {session.TeacherName}
            </Text></td>
            
            <td><Text as="strong" fontWeight={700}>
              {session.Date}
            </Text></td>
            <td><Text as="strong" fontWeight={700} type="time">
              {session.Time}
            </Text></td>
            <td>
            <Button variation="link" onClick={() => {}}>
              Edit session
            </Button>
            </td>
            <td>
            <Button variation="link" onClick={() => deleteDanceSession(session)}>
              Delete session
            </Button>
            </td>
            </tr>
            
            
            
            
          
        ))}
      </tbody></table></View>
      </div>
  );
};

export default SessionsForm;