/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://bgk4wvfirfatranus3kmjcrfg4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uo2lfgjzmvb25h2me7idw3nj6y",
    "aws_cognito_identity_pool_id": "us-east-2:77d32a8b-cb2f-457c-a3c9-daf5fee3a63c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_uD0uWRDxe",
    "aws_user_pools_web_client_id": "nie8a8nv3lnn7pn74t4fq0kva",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "test-bucket195713-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
