import React from 'react';
import ProfileDetails from './ProfileDetails';
import PerformanceTeams from './PerformanceTeams';
import SessionsAttended from './SessionsAttended';
import SessionsTaught from './SessionsTaught';

import './UserProfile.css'; // Import your CSS file for styling



const UserProfile = ( {userProfile} ) => {

  console.log(userProfile);

  // Sample data
  const profileData = {
    name: 'John Doe',
    picture: userProfile.Profile?userProfile.Profile:'default_profile_pic.png',
    favoriteSong: 'Example Song',
    performanceTeams: ['Team A', 'Team B'],
    sessionsAttended: ['Session 1', 'Session 2'],
    sessionsTaught: ['Session 3', 'Session 4'],
  };

  const sessionsAttended = userProfile.Sessions.items;

  return (
    <div className="user-profile">
      <ProfileDetails name={`${userProfile.FirstName} ${userProfile.LastName}`} picture={profileData.picture} favoriteSong={profileData.favoriteSong} />
      <SessionsAttended sessions={sessionsAttended} />
      <PerformanceTeams teams={profileData.performanceTeams} />
      <SessionsTaught sessions={profileData.sessionsTaught} />
    </div>
  );
};

export default UserProfile;
