import React from 'react';

const SessionsTaught = ({ sessions }) => {
  return (
    <div className="sessions-taught">
      <h2>Sessions Taught</h2>
      <ul>
        {sessions.map((session, index) => (
          <li key={index}>{session}</li>
        ))}
      </ul>
    </div>
  );
};

export default SessionsTaught;
