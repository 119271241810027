// MembersList.js
import React, {useState, useEffect} from 'react';
import { API } from 'aws-amplify';
import PageTemplate from './PageTemplate';
import UserProfile from './UserProfile';

import "./MembersList.css"

import { listUsers } from '../graphql/queries';

const MembersList = ({}) => {
    const [members, setMembers] = useState(null);

    async function listMembersQuery(){
        const membersData = await API.graphql({
          query: listUsers,
          variables: {
            
          }
        })
    
        const members = membersData.data.listUsers;
    
        console.log(members);
        setMembers(members.items);
      }

      useEffect(() => {
        listMembersQuery();
      }, []);

  return (
    <div className='members-list'>
        

        { 
            members?
            members.map((attendee,index) => (
                <div key={index} className='user-row-display'>
                    
                    <img className='profile-thumbnail' src='./default_profile_pic.png' alt="profile"/>
                    <div>
                    {attendee.FirstName} {attendee.LastName}
                     </div>
                    
                </div>
            )):<></>}

    </div>
  );
};

export default MembersList;