function formatDate(inputDate) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dateParts = inputDate.split('-'); // Split the date into [year, month, day]

    const year = dateParts[0];
    const month = months[parseInt(dateParts[1], 10) - 1]; // Convert month to zero-based index
    const day = parseInt(dateParts[2], 10); // Remove any leading zeros

    return `${month} ${day}, ${year}`;
}

function formatTime(inputTime) {
    const timeParts = inputTime.split(':'); // Split the time into [hour, minute, second]

    let hour = parseInt(timeParts[0], 10);
    const minute = timeParts[1];
    const ampm = hour >= 12 ? 'PM' : 'AM';

    hour = hour % 12;
    hour = hour ? hour : 12; // Convert hour '0' to '12'

    return `${hour}:${minute} ${ampm}`;
}

export {formatDate, formatTime};