import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { API, Storage } from "aws-amplify";
import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
  TextField,
  DateField,
  View,
  Card,
  withAuthenticator,
} from "@aws-amplify/ui-react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import PastSessions from './components/PastSessions';
import Calendar from './components/CalendarPage';
import People from './components/PeoplePage';
import Home from './components/HomePage';
import DanceSessionPage from "./components/DanceSessionPage";

import { Authenticator } from '@aws-amplify/ui-react';

import Protected  from './components/Protected';
import { RequireAuth } from './components/RequireAuth';
import { Login } from './components/Login';
import { WithAdminAuthorization } from "./components/WithAdmin";
import AdminCreatePage from "./components/AdminQueryPage";
import UserProfile from "./components/UserProfile";
import UserProfilePage from "./components/UserProfilePage";
import Footer from "./components/Footer";




function MyRoutes(){
  //Write function to fetch DanceClass data

  return (
    <Router>
      
      <Navbar />
      <Routes>
        <Route path="/past-sessions" element={ <PastSessions/> } />
        <Route path="/calendar" element={ <Calendar/>} />
        <Route path="/people" element={<People/>} />
        <Route path="/" exact element={<Home/>}/>
        <Route path="/session/:sessionId" element={ <DanceSessionPage/>} />
        <Route path="/user/:userId" element={<UserProfilePage />} />
        <Route path="/admin" element={<AdminCreatePage />} />
        <Route
            path="/protected"
            element={
              <RequireAuth>
                <Protected />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
      </Routes>
      <Footer />
      
    </Router>
    
  );
};

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;


