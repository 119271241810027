// PastSessions.js
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import './LocationsForm.css'; // Import your CSS file for stylin

import { listLocations } from "../../graphql/queries";

import {
  createLocation as createLocationMutation,
  deleteLocation as deleteLocationMutation,
} from "../../graphql/mutations";


const LocationsForm = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    fetchLocations();
  }, []);

  async function fetchLocations() {
    const apiData = await API.graphql({ query: listLocations });
    const locationsFromAPI = apiData.data.listLocations.items;
    setLocations(locationsFromAPI);
  }

  async function createLocation(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      name: form.get("name"),
    };
    await API.graphql({
      query: createLocationMutation,
      variables: { input: data },
    });
    fetchLocations();
    event.target.reset();
  }

  async function deleteLocation({ id }) {
    const newLocations = locations.filter((location) => location.id !== id);
    setLocations(newLocations);
    await API.graphql({
      query: deleteLocationMutation,
      variables: { input: { id } },
    });
  }
  return (
      <div className="admin-form">
        <h2>Create Location</h2>
        <View className="admin-view" as="form" margin="3rem 0" onSubmit={createLocation}>
        <Flex direction="row" justifyContent="center">
          <TextField
            name="name"
            placeholder="Location Name"
            label="Location Name"
            labelHidden
            variation="quiet"
            required
          />
          <Button type="submit" variation="primary">
            Create Location
          </Button>
        </Flex>
      </View>
      <h2>Locations</h2>
      <View margin="1rem 0">
        {locations.map((location) => (
          <Flex
            key={location.id || location.name}
            direction="row"
            justifyContent="space-between"
            alignItems="left"
          >
            <Text as="strong" fontWeight={700}>
              {location.name}
            </Text>
            
            <Button variation="link" onClick={() => deleteLocation(location)}>
              Delete location
            </Button>
          </Flex>
        ))}
      </View>
      </div>
  );
};

export default LocationsForm;