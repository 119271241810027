// PastSessions.js
import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { Link } from "react-router-dom"
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
  Autocomplete
} from "@aws-amplify/ui-react";
import './UsersForm.css'; // Import your CSS file for stylin

import { listUsers, listSessionAttendees } from "../../graphql/queries";

import {
  createUser as createUserMutation,
  deleteSessionAttendees,
  deleteUser as deleteUserMutation,
} from "../../graphql/mutations";


const UsersForm = () => {
  const [users, setUsers] = useState([]);
  
  useEffect(() => {
    fetchUsers();
    
  }, []);

  async function fetchUsers() {
    const apiData = await API.graphql({ query: listUsers });
    const usersFromAPI = apiData.data.listUsers.items;
    setUsers(usersFromAPI);
    
  }

  async function createUser(event) {
    
    event.preventDefault();
    const form = new FormData(event.target);
    const image = form.get("image");
    console.log(image.name);
    const data = {
      FirstName: form.get("firstName"),
      LastName: form.get("lastName"),
      Email: form.get("email"),
      //ProfilePicture: image.name
      
    };
    
    //if (!!data.ProfilePicture) await Storage.put(image.name, image);
    console.log(data);
    await API.graphql({
      query: createUserMutation,
      variables: { input: data },
    });
    fetchUsers();
    event.target.reset();
  }

  async function editUser(event) {

  }

  async function deleteSessionAttendeesByUser(id){
    console.log(id);
    const attendeesData = await API.graphql({
      query: listSessionAttendees,
      variables: {
        filter: {userId: {eq: id}}
      }
    })

    const sessionAttendees = attendeesData.data.listSessionAttendees.items;

      for(const sessionAttendee of sessionAttendees){
        console.log(sessionAttendee.id);
        const sessionAttendeeId = sessionAttendee.id;

        await API.graphql({
          query: deleteSessionAttendees,
          variables: {input: {id:sessionAttendeeId}}
        });
      }
    
      
  }

  async function deleteUser({ id, name }) {
    const newUsers = users.filter((user) => user.id !== id);
    setUsers(newUsers);
    //await Storage.remove(name);

    await deleteSessionAttendeesByUser(id);

    await API.graphql({
      query: deleteUserMutation,
      variables: { input: { id } },
    });

  }

  if(!users){
    return(<div className="admin-form">Loading...</div>)
  }
  return (
      <div className="admin-form">
        <h2>Create User</h2>
        <View className="admin-view" as="form" margin="3rem 0" onSubmit={createUser}>
        <Flex direction="row" justifyContent="left" style={{"margin-bottom":"8px"}}>
          <TextField
            name="firstName"
            placeholder="First Name"
            label="First Name"
            labelHidden
            variation="quiet"
            required
          />
          <TextField
            name="lastName"
            placeholder="Last Name"
            label="Last Name"
            labelHidden
            variation="quiet"
            required
          />
          <TextField
            name="email"
            placeholder="Email"
            label="Email"
            type="email"
            labelHidden
            variation="quiet"
            required
          />
          </Flex>
          <Flex direction="row" justifyContent="left" style={{"margin-bottom":"8px"}}>
          
          
          <View
            name="image"
            as="input"
            type="file"
            style={{ alignSelf: "end" }}
          />
          </Flex>
          <Flex direction="row" justifyContent="right" style={{"margin-bottom":"8px"}}>
          <Button type="submit" variation="primary">
            Create User
          </Button>
        </Flex>
      </View>

      <h2>Users</h2>
      <View margin="1rem 0">
        <table className="sessions-table">
          <tbody>
          <tr>
            <th>Link</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        {!users?<></>:users.sort((a, b) => a.FirstName[0] - b.FirstName[0]).map((user) => (
          
            <tr key={user.id || user.FirstName}>
              
              <td>
                  <Link to={`/user/${user.id}`}>View User</Link>
              </td>
              <td><Text as="strong" fontWeight={700}>
              {user.FirstName}
            </Text></td>
            
            <td><Text as="strong" fontWeight={700}>
              {user.LastName}
            </Text></td>
            <td><Text as="strong" fontWeight={700} type="time">
              {user.Email}
            </Text></td>
            <td>
            <Button variation="link" onClick={() => {}}>
              Edit user
            </Button>
            </td>
            <td>
            <Button variation="link" onClick={() => deleteUser(user)}>
              Delete user
            </Button>
            </td>
            </tr>
        ))}
      </tbody></table></View>
      </div>
  );
};

export default UsersForm;