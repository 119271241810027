// UserProfilePage.js
import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { API, Storage } from "aws-amplify";

import PageTemplate from './PageTemplate';
import UserProfile from './UserProfile';

import { getUserCustom } from '../graphql/custom-queries';
import { graphqlOperation } from 'aws-amplify';

const UserProfilePage = () => {

    const params = useParams();
    const userId = params.userId;
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            try{
                const userData = await API.graphql(graphqlOperation(getUserCustom, {id: userId}));
                const user = userData.data.getUser;
                if(user.ProfilePicture){
                    const url = await Storage.get(user.ProfilePicture);
                    user.ProfilePicture = url;
                }

                setUser(user);

            } catch (error) {
                console.error("Error fetching user:", error);
            }
        };

        fetchUser();
    }, [userId]);
    

    if(!user){
        return <div>Loading user...</div>
    }

  return (

    <PageTemplate>
      
      <UserProfile userProfile={user}/>
      {/* Other content specific to Past Sessions page */}
    </PageTemplate>
  );
};

export default UserProfilePage;