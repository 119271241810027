// PastSessions.js
import React from 'react';
import PageTemplate from './PageTemplate';

const CalendarPage = () => {
  return (
    <PageTemplate>
      <h1>Calendar</h1>
      {/* Other content specific to Past Sessions page */}
    </PageTemplate>
  );
};

export default CalendarPage;