import React from 'react';
import './UpcomingEventsList.css'; // Import your CSS file for styling

const UpcomingEventsList = ({ upcomingEvents }) => {
  return (
    <div className="upcoming-events-list">
      {upcomingEvents.map((event, index) => (
        <div className="event" key={index}>
          <div className="event-header">
            <div className="event-date">{event.date}</div>
            <div className="event-location">Location: {event.location}</div>
          </div>
          <div className="event-description">{event.description}</div>
        </div>
      ))}
    </div>
  );
};

export default UpcomingEventsList;
