// PastSessions.js
import React from 'react';
import PageTemplate from './PageTemplate';
import DanceSessionCard from './DanceSessionCard';
import DanceSessionList from './DanceSessionList'
import UpcomingEventsList from './UpcomingEventsList';
import Announcements from './Announcements';
import IntroCarousel from './IntroCarousel';


import './HomePage.css'
import { Grid, Card, View } from '@aws-amplify/ui-react';

const sessionList = [
    {"thumbnailUrl":"holdmedown.png", "videoLink":"https://www.youtube.com/uwhiphop", "title":"Hold Me Down", "choreographer":"Jeanelle Macatangay", "date":"Oct. 19, 2023"},
    {"thumbnailUrl":"scatterbrain.png", "videoLink":"https://www.youtube.com/uwhiphop", "title":"Scatter Brain", "choreographer":"Hamza Sayed-Ali", "date":"Oct. 17, 2023"},
    {"thumbnailUrl":"maniac.png", "videoLink":"https://www.youtube.com/uwhiphop", "title":"Maniac", "choreographer":"Keerthi Kiran", "date":"Oct. 5, 2023"},
    {"thumbnailUrl":"", "videoLink":"https://www.youtube.com/uwhiphop", "title":"Seven", "choreographer":"Owen Kim", "date":"Oct. 3, 2023"},
    {"thumbnailUrl":"", "videoLink":"https://www.youtube.com/uwhiphop", "title":"You Got It", "choreographer":"Laettner Calderon", "date":"Sep. 28, 2023"},
    {"thumbnailUrl":"", "videoLink":"https://www.youtube.com/uwhiphop", "title":"King's Dead", "choreographer":"Ben Lin", "date":"Sep. 26, 2023"},
]

const upcomingEventsData = [
    {
      date: 'January 15, 2024',
      location: 'SLC',
      description: 'Clubs Fair!',
    },
    {
      date: 'January 16, 2024',
      location: 'SLC',
      description: 'Clubs Fair!',
    },
    {
        date: 'November 22, 2023',
        location: 'SLC Basement',
        description: 'Event Description 1',
      },
      {
        date: 'November 25, 2023',
        location: 'M3',
        description: 'Event Description 2',
      },
      {
        date: 'November 15, 2023',
        location: 'Event Location 1',
        description: 'Event Description 1',
      },
      {
        date: 'November 20, 2023',
        location: 'Event Location 2',
        description: 'Event Description 2',
      },
      {
        date: 'November 15, 2023',
        location: 'Event Location 1',
        description: 'Event Description 1',
      },
      {
        date: 'November 20, 2023',
        location: 'Event Location 2',
        description: 'Event Description 2',
      },
    // Add more upcoming events as needed
  ];

const HomePage = () => {
  return (
    <PageTemplate>
      {/* Other content specific to Past Sessions page */}
      <IntroCarousel />
      <Grid
      templateColumns={{ base: '1fr', large: '1fr 1fr' }}
      
      
      
      
    >
      <View>
      <h2>Announcements</h2>
        <Announcements />
      </View>
      <View>
      <h2>Upcoming</h2>
        <UpcomingEventsList upcomingEvents={upcomingEventsData} />
      </View>
      
    </Grid>
      
      
      

      <div className='home-row'>
      

      
      <div>
        <h2>Past Sessions</h2>
        <DanceSessionList danceSessions={sessionList}/>
      </div>
      </div>

      
    </PageTemplate>
  );
};

export default HomePage;