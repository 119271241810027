import React, {useState, useEffect} from 'react';
import { API, Storage } from "aws-amplify";
import { useParams } from 'react-router-dom';
import PageTemplate from './PageTemplate';
import { graphqlOperation } from 'aws-amplify';
import { Auth } from 'aws-amplify';

import { Button } from '@aws-amplify/ui-react';
import { getDanceSession, listSessionAttendees } from '../graphql/queries';
import {getDanceSessionCustom} from "../graphql/custom-queries";
import { createSessionAttendees } from '../graphql/mutations';

import { listSessionAttendeesNames } from "../graphql/custom-queries"

import './DanceSessionPage.css'

import { formatDate, formatTime } from '../helpers';
import AttendeesList from './AttendeesList'
import AtteendeesForm from './forms/AttendeesForm';


const DanceSessionPage = () => {
  const params = useParams();
  const sessionId = params.sessionId;
  const [session, setSession] = useState(null);
  const [attendees, setAttendees] = useState(null);
  const [authenticated, setAuth] = useState(null);

  async function checkAuth(){
    try{
      await Auth.currentAuthenticatedUser();
      setAuth(true);
    }
    catch{
      setAuth(false);
    }
    
  }


  async function listSessionAttendeesQuery(){
    const attendeesData = await API.graphql({
      query: listSessionAttendees,
      variables: {
        filter: {danceSessionId: {eq: "8903014a-ec02-46c7-b04b-0273aae13ac0"}}
      }
    })

    const attendees = attendeesData.data.listSessionAttendees;

    console.log(attendees)
  }
  async function createSessionAttendeesTest(){
    const data = {
      danceSessionId: sessionId,
      userId: "dda3b67b-ecc9-4341-93ab-f1ebc822a4e1",
    };

    
  // connect post and tag
  await API.graphql({
      query: createSessionAttendees,
      variables: {
        input: data
      }
    });

    

  } 

  async function fetchAttendees(id){
    const attendeesData = await API.graphql({
        query: listSessionAttendeesNames,
        variables: {
          filter: {danceSessionId: {eq: id}}
        }
      })
  
      const attendees = attendeesData.data.listSessionAttendees;

      console.log(attendees.items);
      setAttendees(attendees.items);
  }

 

  useEffect(() => {
    const fetchSession = async () => {
        try {
          console.log(sessionId);
            const sessionData = await API.graphql(graphqlOperation(getDanceSessionCustom, { id: sessionId }));
            const session = sessionData.data.getDanceSession;
            
            if (session.Thumbnail) {
              const url = await Storage.get(session.Thumbnail);
              session.Thumbnail = url;
            }

            console.log(session.Attendees.items);
            
            setSession(session);
            
        } catch (error) {
            console.error("Error fetching session:", error);
        }
        
    };

    fetchSession();
}, [sessionId]);

useEffect(()=>{
  checkAuth();
})

if (!session) {
  return <div>Loading session...</div>;
}

  return (
    <PageTemplate>
      <h1>{session?
        (session.TeacherName):""}
        </h1>
      <div className='dance-session-page'>

      <div className='dance-session-details'>
      
        <h2>Song: {session.SongName}</h2>
        <h3>{formatDate(session.Date)} | {formatTime(session.Time)}</h3>
        {session.Thumbnail && <img src={session.Thumbnail} className='session-page-image' alt='session page thumbnail'/>}
        </div>
        <div>
        

        <h2>Attendees ({session.Attendees.items.length})</h2>
        <AttendeesList attendees={session.Attendees.items}/>

        
        
        </div>

        
        
        </div>
      
      {/* Other content specific to Past Sessions page */}
      <div className='admin-forms'>
          {!authenticated?<></>:
          <AtteendeesForm sessionId={sessionId}/>}
        </div>
    </PageTemplate>
  );
};

export default DanceSessionPage;