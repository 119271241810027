/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDanceSession = /* GraphQL */ `
  mutation CreateDanceSession(
    $input: CreateDanceSessionInput!
    $condition: ModelDanceSessionConditionInput
  ) {
    createDanceSession(input: $input, condition: $condition) {
      id
      Date
      Time
      TeacherName
      SongName
      Location {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      Thumbnail
      VideoLink
      Attendees {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      danceSessionLocationId
      __typename
    }
  }
`;
export const updateDanceSession = /* GraphQL */ `
  mutation UpdateDanceSession(
    $input: UpdateDanceSessionInput!
    $condition: ModelDanceSessionConditionInput
  ) {
    updateDanceSession(input: $input, condition: $condition) {
      id
      Date
      Time
      TeacherName
      SongName
      Location {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      Thumbnail
      VideoLink
      Attendees {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      danceSessionLocationId
      __typename
    }
  }
`;
export const deleteDanceSession = /* GraphQL */ `
  mutation DeleteDanceSession(
    $input: DeleteDanceSessionInput!
    $condition: ModelDanceSessionConditionInput
  ) {
    deleteDanceSession(input: $input, condition: $condition) {
      id
      Date
      Time
      TeacherName
      SongName
      Location {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      Thumbnail
      VideoLink
      Attendees {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      danceSessionLocationId
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      FirstName
      LastName
      Email
      ProfilePicture
      Sessions {
        nextToken
        __typename
      }
      ActiveMembership
      Private
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      FirstName
      LastName
      Email
      ProfilePicture
      Sessions {
        nextToken
        __typename
      }
      ActiveMembership
      Private
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      FirstName
      LastName
      Email
      ProfilePicture
      Sessions {
        nextToken
        __typename
      }
      ActiveMembership
      Private
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      session {
        id
        Date
        Time
        TeacherName
        SongName
        Thumbnail
        VideoLink
        createdAt
        updatedAt
        danceSessionLocationId
        __typename
      }
      user {
        id
        FirstName
        LastName
        Email
        ProfilePicture
        ActiveMembership
        Private
        createdAt
        updatedAt
        __typename
      }
      text
      Date
      Time
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      session {
        id
        Date
        Time
        TeacherName
        SongName
        Thumbnail
        VideoLink
        createdAt
        updatedAt
        danceSessionLocationId
        __typename
      }
      user {
        id
        FirstName
        LastName
        Email
        ProfilePicture
        ActiveMembership
        Private
        createdAt
        updatedAt
        __typename
      }
      text
      Date
      Time
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      session {
        id
        Date
        Time
        TeacherName
        SongName
        Thumbnail
        VideoLink
        createdAt
        updatedAt
        danceSessionLocationId
        __typename
      }
      user {
        id
        FirstName
        LastName
        Email
        ProfilePicture
        ActiveMembership
        Private
        createdAt
        updatedAt
        __typename
      }
      text
      Date
      Time
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      Date
      Time
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      Date
      Time
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      Date
      Time
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      id
      Title
      Message
      EndDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      id
      Title
      Message
      EndDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      id
      Title
      Message
      EndDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSessionAttendees = /* GraphQL */ `
  mutation CreateSessionAttendees(
    $input: CreateSessionAttendeesInput!
    $condition: ModelSessionAttendeesConditionInput
  ) {
    createSessionAttendees(input: $input, condition: $condition) {
      id
      danceSessionId
      userId
      danceSession {
        id
        Date
        Time
        TeacherName
        SongName
        Thumbnail
        VideoLink
        createdAt
        updatedAt
        danceSessionLocationId
        __typename
      }
      user {
        id
        FirstName
        LastName
        Email
        ProfilePicture
        ActiveMembership
        Private
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSessionAttendees = /* GraphQL */ `
  mutation UpdateSessionAttendees(
    $input: UpdateSessionAttendeesInput!
    $condition: ModelSessionAttendeesConditionInput
  ) {
    updateSessionAttendees(input: $input, condition: $condition) {
      id
      danceSessionId
      userId
      danceSession {
        id
        Date
        Time
        TeacherName
        SongName
        Thumbnail
        VideoLink
        createdAt
        updatedAt
        danceSessionLocationId
        __typename
      }
      user {
        id
        FirstName
        LastName
        Email
        ProfilePicture
        ActiveMembership
        Private
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSessionAttendees = /* GraphQL */ `
  mutation DeleteSessionAttendees(
    $input: DeleteSessionAttendeesInput!
    $condition: ModelSessionAttendeesConditionInput
  ) {
    deleteSessionAttendees(input: $input, condition: $condition) {
      id
      danceSessionId
      userId
      danceSession {
        id
        Date
        Time
        TeacherName
        SongName
        Thumbnail
        VideoLink
        createdAt
        updatedAt
        danceSessionLocationId
        __typename
      }
      user {
        id
        FirstName
        LastName
        Email
        ProfilePicture
        ActiveMembership
        Private
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
