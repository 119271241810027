import React from 'react';
import {Link} from 'react-router-dom';

const SessionsAttended = ({ sessions }) => {
  console.log(sessions);
  return (
    <div className="sessions-attended">
      <h2>Sessions Attended ({sessions.length})</h2>
      <div className='sessions-attended-list'>
      {sessions.map((session, index) => (
          <div key={index} className='sessions-attended-list-item'>
            
            <Link className="undecorated-link" to={`/session/${session.danceSession.id}`}>{session.danceSession.SongName}</Link>
            
            </div>
        ))}
      </div>
        
      
    </div>
  );
};

export default SessionsAttended;
