import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';

import {Amplify, Auth} from 'aws-amplify'

export const WithAdminAuthorization = async (Component) => {
    const user =  await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      const isAdmin = true;
      
  return class extends React.Component {
    
    render() {

        
      if (!isAdmin) {
        return <div>Access Denied. Only admin users are allowed.</div>;
      }

      return <Component {...this.props} />;
    }
  };
};