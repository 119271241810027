// PastSessions.js
import React from 'react';
import PageTemplate from './PageTemplate';
import UserProfile from './UserProfile';
import MembersList from './MembersList';

const PeoplePage = () => {
  return (
    <PageTemplate>
      <h1>People</h1>
      <MembersList />
      {/* Other content specific to Past Sessions page */}
    </PageTemplate>
  );
};

export default PeoplePage;