export const getDanceSessionCustom = /* GraphQL */ `
  query GetDanceSession($id: ID!) {
    getDanceSession(id: $id) {
      id
      Date
      Time
      TeacherName
      SongName
      Location {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      Thumbnail
      VideoLink
      Attendees {
        items {
          user {
            id
            FirstName
            LastName
          }
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      danceSessionLocationId
      __typename
    }
  }
`;

export const getUserCustom = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      FirstName
      LastName
      Email
      Sessions {
        items {
            danceSession {
              SongName
              Date
              id
            }
        }
        
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const listSessionAttendeesNames = /* GraphQL */ `
  query ListSessionAttendees(
    $filter: ModelSessionAttendeesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionAttendees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        danceSessionId
        userId
        createdAt
        updatedAt
        user{
            FirstName
            LastName
            id
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;