import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import DanceSessionCard from './DanceSessionCard';
import './DanceSessionList.css'; // Import your CSS file for styling

import { listDanceSessions } from "../graphql/queries";

const DanceSessionList = ({ danceSessions }) => {
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    fetchSessions();
  }, []);

  async function fetchSessions() {
    const apiData = await API.graphql({ query: listDanceSessions });
    const sessionsFromAPI = apiData.data.listDanceSessions.items;
    await Promise.all(
      sessionsFromAPI.map(async (session) => {
        if (session.Thumbnail) {
          const url = await Storage.get(session.Thumbnail);
          session.Thumbnail = url;
        }
        return session;
      })
    );
    setSessions(sessionsFromAPI);
  }

  return (
    <div className="dance-session-list">
      {sessions.sort((a, b) => new Date(b.Date) - new Date(a.Date)).map((session, index) => (
        <DanceSessionCard
          key={index}
          thumbnailFileName={session.Thumbnail}
          videoLink={session.videoLink}
          title={session.SongName}
          choreographer={session.TeacherName}
          date={session.Date}
          id={session.id}
        />
      ))}
    </div>
  );
};

export default DanceSessionList;
