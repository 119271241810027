import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { Link } from "@aws-amplify/ui-react";

import './AttendeesList.css'


const AttendeesList = ( {attendees} ) => {

    


    return(
        <>
        <div className="attendees-list">

            { 
            attendees?
                attendees.map((attendee,index) => (
                <div key={index}>
                    <Link className="user-link" href={`/user/${attendee.user.id}`}>{attendee.user.FirstName} {attendee.user.LastName}</Link>
                    
                </div>
            )):<></>}
        </div>
        </>
    );
};

export default AttendeesList;