import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './IntroCarousel.css';

class IntroCarousel extends Component {
    render() {
        return (
            <Carousel className="intro-carousel" showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true} interval={5000}>
                <div className='carousel-element'>
                    <img src="carousel3.gif" />
                    
                    
                </div>
                <div>
                    <img src="carousel1.jpg" alt="merch"/>
                    
                    
                </div>
                <div>
                    <img src="carousel2.png" alt="performance team"/>
                    
                </div>
                
            </Carousel>
        );
    }
};



export default IntroCarousel;