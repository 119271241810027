// PastSessions.js
import React, { useState, useEffect } from "react";
import PageTemplate from './PageTemplate';
import { API } from "aws-amplify";
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import './AdminQueryPage.css'; // Import your CSS file for stylin

import { listLocations } from "../graphql/queries";

import {
  createLocation as createLocationMutation,
  deleteLocation as deleteLocationMutation,
} from "../graphql/mutations";
import LocationsForm from "./forms/LocationsForm";
import SessionsForm from "./forms/SessionsForm";
import UsersForm from "./forms/UsersForm";


const AdminCreatePage = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    fetchLocations();
  }, []);

  async function fetchLocations() {
    const apiData = await API.graphql({ query: listLocations });
    const locationsFromAPI = apiData.data.listLocations.items;
    setLocations(locationsFromAPI);
  }

  async function createLocation(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      name: form.get("name"),
    };
    await API.graphql({
      query: createLocationMutation,
      variables: { input: data },
    });
    fetchLocations();
    event.target.reset();
  }

  async function deleteLocation({ id }) {
    const newLocations = locations.filter((location) => location.id !== id);
    setLocations(newLocations);
    await API.graphql({
      query: deleteLocationMutation,
      variables: { input: { id } },
    });
  }
  return (
    <PageTemplate>
      {/* Other content specific to Past Sessions page */}
      <div className="admin-query-page-body">
      
      <div className="flex-row">
      <SessionsForm/>
      </div>
      <div className="flex-row">
      <UsersForm />
      </div>
      <div className="flex-row">
      <LocationsForm/>
      
      
      </div>
      </div>
      
      
      
    </PageTemplate>
  );
};

export default AdminCreatePage;