// PastSessions.js
import React from 'react';
import PageTemplate from './PageTemplate';
import DanceSessionList from './DanceSessionList';

const PastSessions = () => {
  return (
    <PageTemplate>
      <h1>Past Sessions</h1>
      <DanceSessionList/>
      {/* Other content specific to Past Sessions page */}
    </PageTemplate>
  );
};

export default PastSessions;