import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import './Announcements.css'; // Import your CSS file for styling

import { listAnnouncements } from '../graphql/queries';

const Announcements = () => {
    const [announcements, setAnnouncements] = useState([]);

    useEffect(()=>{fetchAnnouncements()}, []);

    async function fetchAnnouncements(){
        const apiData = await API.graphql(
            {query:listAnnouncements}
            );
        const announcementsFromAPI = apiData.data.listAnnouncements.items;
            console.log(announcementsFromAPI);
        setAnnouncements(announcementsFromAPI);
    }
    
  return (
    <div className="announcements-container">
      {!announcements?<div className='announcement-pill'><p>No announcements to show. Check back later!</p></div>:
      announcements.map((announcement) => (
      <div key={announcement.id}className='announcement-pill'><h3>{announcement.Title}</h3><p>{announcement.Message}</p></div>
      )
        
      )}
    </div>
  );
};

export default Announcements;