/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDanceSession = /* GraphQL */ `
  query GetDanceSession($id: ID!) {
    getDanceSession(id: $id) {
      id
      Date
      Time
      TeacherName
      SongName
      Location {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      Thumbnail
      VideoLink
      Attendees {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      danceSessionLocationId
      __typename
    }
  }
`;
export const listDanceSessions = /* GraphQL */ `
  query ListDanceSessions(
    $filter: ModelDanceSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDanceSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Date
        Time
        TeacherName
        SongName
        Thumbnail
        VideoLink
        createdAt
        updatedAt
        danceSessionLocationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      FirstName
      LastName
      Email
      ProfilePicture
      Sessions {
        nextToken
        __typename
      }
      ActiveMembership
      Private
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        FirstName
        LastName
        Email
        ProfilePicture
        ActiveMembership
        Private
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      session {
        id
        Date
        Time
        TeacherName
        SongName
        Thumbnail
        VideoLink
        createdAt
        updatedAt
        danceSessionLocationId
        __typename
      }
      user {
        id
        FirstName
        LastName
        Email
        ProfilePicture
        ActiveMembership
        Private
        createdAt
        updatedAt
        __typename
      }
      text
      Date
      Time
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        Date
        Time
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      Date
      Time
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        Date
        Time
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID!) {
    getAnnouncement(id: $id) {
      id
      Title
      Message
      EndDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Title
        Message
        EndDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSessionAttendees = /* GraphQL */ `
  query GetSessionAttendees($id: ID!) {
    getSessionAttendees(id: $id) {
      id
      danceSessionId
      userId
      danceSession {
        id
        Date
        Time
        TeacherName
        SongName
        Thumbnail
        VideoLink
        createdAt
        updatedAt
        danceSessionLocationId
        __typename
      }
      user {
        id
        FirstName
        LastName
        Email
        ProfilePicture
        ActiveMembership
        Private
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSessionAttendees = /* GraphQL */ `
  query ListSessionAttendees(
    $filter: ModelSessionAttendeesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionAttendees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        danceSessionId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sessionAttendeesByDanceSessionId = /* GraphQL */ `
  query SessionAttendeesByDanceSessionId(
    $danceSessionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSessionAttendeesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionAttendeesByDanceSessionId(
      danceSessionId: $danceSessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        danceSessionId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sessionAttendeesByUserId = /* GraphQL */ `
  query SessionAttendeesByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSessionAttendeesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionAttendeesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        danceSessionId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
