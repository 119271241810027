import React from 'react';
import './Footer.css'; // Import your CSS file for stylin

const Footer = () => {
  

  return (
    <div className="footer">
        <div className='divider'/>
     
</div>
    
  );
};

export default Footer;