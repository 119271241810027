import React from 'react';
import './DanceSessionCard.css'; // Import your CSS file for stylin

import { formatDate } from '../helpers';
const formatSessionURL = (id) => {
    // Remove spaces and convert to lowercase
    
    return `/session/${id}`;
  };

const DanceSessionCard = ({ id, thumbnailFileName, videoLink, title, choreographer, date }) => {

    const defaultVideoLink = 'https://www.example.com/default-video'; // Set your default video link here
    const finalVideoLink = videoLink || defaultVideoLink; // Use provided videoLink or defaultVideoLink if videoLink is empty

    const sessionLink = formatSessionURL(id)

    const renderThumbnail = () => {
        if (thumbnailFileName) {
          return (
            <a href={finalVideoLink} target="_blank" rel="noopener noreferrer">
              <img className="dance-session-card-thumbnail" src={thumbnailFileName} alt="Dance Session Thumbnail" />
            </a>
          );
        } else {
          // If thumbnailUrl is not provided, render a placeholder rectangle
          return <a href={finalVideoLink} target="_blank" rel="noopener noreferrer" > <div className="placeholder-thumbnail">?</div></a>;
        }
      };

  return (
    <div className="dance-session-card">
        
      <div className="thumbnail">
        {renderThumbnail()}
      </div>
      <div className="session-details">
        <h2 className="title">🎵  : {title}</h2>
        <p className="choreographer">💃 : {choreographer}</p>
        <p className="date">📅 : {formatDate(date)}</p>
      </div>
      <a className="arrow-button" href={sessionLink}>→</a>
      
    </div>
  );
};

export default DanceSessionCard;