import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import AttendeesList from "../AttendeesList";
import { Autocomplete, Button, Flex, View } from "@aws-amplify/ui-react";

import './AttendeesForm.css'

import {  listUsers } from "../../graphql/queries";
import {listSessionAttendeesNames } from "../../graphql/custom-queries"
import { createSessionAttendees, deleteSessionAttendees } from "../../graphql/mutations";

const AtteendeesForm = ({sessionId}) => {

    const [attendees, setAttendees] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    useEffect(()=>{
        fetchAttendees(sessionId);
    }, [sessionId]);

    useEffect(()=>{
        fetchUsers(attendees);
    },[attendees]);

    

    async function fetchUsers(test){
        
        const apiData = await API.graphql(
            {query:listUsers}
            );
        var usersFromAPI = apiData.data.listUsers.items;

        if(test){
            usersFromAPI = usersFromAPI.filter((user)=>{
            
                const found = test.some(el => el.userId === user.id)
                return !found;
            })
        }

        setUsers(usersFromAPI);
    }

    async function fetchAttendees(id){
        const attendeesData = await API.graphql({
            query: listSessionAttendeesNames,
            variables: {
              filter: {danceSessionId: {eq: id}}
            }
          })
      
          const attendees = attendeesData.data.listSessionAttendees;

          console.log(attendees.items);
          setAttendees(attendees.items);
    }

    async function removeAttendee(selectedAttendee){
        const id = selectedAttendee.id;
        const newAttendees = attendees.filter((user)=>user.id !==selectedAttendee.id);
        setAttendees(newAttendees);
        await API.graphql({
            query: deleteSessionAttendees,
            variables: {input: { id }},
        });
        
    }

    async function createAttendee(event){
        console.log(selectedUser);
        if(selectedUser===""){
            event.target.reset();
            return;
        }
        event.preventDefault();
        const form = new FormData(event.target);

        console.log(selectedUser);
        
        
        const data = {
            danceSessionId: sessionId,
            userId: selectedUser,
        };
    
        // connect post and tag
        await API.graphql({
            query: createSessionAttendees,
            variables: {
            input: data
            }
        }); 
        fetchAttendees(sessionId);
        event.target.reset();
    }


    return (
        <div className="">
            <h2>Check In {!attendees?<></>:"("+attendees.length+")"}</h2>

            <View className="add-attendee-form admin-view" as="form" onSubmit={createAttendee}>
                <Flex direction={"row" } justifyContent={"left"}>
                    <Autocomplete className="white-bg" 
                    label="User"
                    labelHidden
                    name="test"
                    options={!users?<></>:users.map(test=>({
                        id:test.id,
                        label: test.FirstName+" "+test.LastName,
                    }))
   
                    }
                    onSelect={(option)=>{
                        setSelectedUser(option.id)
                    }

                    }/> 

                    <Button type="submit" variation="primary">
                        +
                    </Button>
                </Flex>
            </View>
            
            <div className="atttendees-table-frame">
            <table className="attendees-table">
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Remove</th>
                    </tr>
                    { 
                        !attendees?<></>:
                            attendees.map((attendee,index) => (
                                <tr key={attendee.userId}>
                                    <td>{attendee.user.FirstName} {attendee.user.LastName}</td>
                                    <td>
                                    <Button variation="link" onClick={() =>removeAttendee(attendee)}>
                                        Remove
                                    </Button>
                                    </td>
                                </tr>
                            
                        ))
                        }
                </tbody>
            </table>
            </div>

        </div>
    );
};

export default AtteendeesForm;